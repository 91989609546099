@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
  background: #fff center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: none !important;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

._new {
  width: 100%;
  left: -20px;
  list-style: none;
  position: absolute;
  display: flex !important;
  justify-content: center;

  list-style: none;
  text-align: center;
}

._new li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  padding: 0;
  display: inline-block;

  cursor: pointer;
}

._new li button {
  width: 6px;
  height: 6px;
  display: block;
  padding: 0;

  font-size: 0;
  line-height: 0;
  text-align: center;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

._new li button:hover,
._new li button:focus {
  outline: none;
}
._new li button:hover:before,
._new li button:focus:before {
  opacity: 1;
}
._new li button:before {
  width: 6px;
  height: 6px;
  font-size: 24px;

  top: 0;
  left: 0;

  text-align: center;

  color: #ebebeb;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
._new li.slick-active button:before {
  color: #444444;
}
._new li img {
  opacity: 0.6;
}
._new li.slick-active img {
  opacity: 1;
}

.slick-dots {
  width: 100%;
  height: 6px;
  padding: 0;
  margin: 12px 0 0;

  display: flex !important;
  justify-content: center;

  list-style: none;
  text-align: center;
}
.slick-dots li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  padding: 0;
  display: inline-block;

  cursor: pointer;
}
.slick-dots li button {
  width: 6px;
  height: 6px;
  display: block;
  padding: 0;

  font-size: 0;
  line-height: 0;
  text-align: center;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  width: 6px;
  height: 6px;
  font-size: 24px;

  /* top: 0;
  left: 0; */

  content: "•";
  text-align: center;

  color: #ebebeb;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #444444;
}
.slick-dots li img {
  opacity: 0.13;
}
.slick-dots li.slick-active img {
  opacity: 1;
}

.article-title {
  margin: 0 0 16px 0;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: var(--color-black);
  word-break: break-word;
}
.article-title .article-br {
   display: none;
 }

.article {
  width: 100%;
  max-width: 500px;
  margin-bottom: 40px;
}
div.article-image {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
div.article-image div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
div.article-image div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 327px;
  max-width: 468px;
}
div.article-images {
  width: 100%;
  padding: 0 16px;
  display: flex;
  overflow-x: scroll;
}
div.article-images div {
  padding-right: 8px;
  display: inline-block;
  height: 327px;
}
div.article-images div img {
  height: 100%;
}

div.article-content {
  padding: 0 16px;
}
div.article-content img {
  width: 100%;
}

p.article-subtitle {
  padding: 0 16px;
  margin: 0 0 40px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black);
}
p.article-body {
  padding: 0 16px;
  margin: 28px 0 16px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-secondary-black);
  word-break: break-all;
}
p.article-body span.article-body-heading {
  display: block;
  font-weight: bold;
  font-size: 16px;
}
p.article-caption {
  padding: 0 16px;
  margin: 8px 0 48px 0;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: var(--color-tertiary-black);
  word-break: break-all;
  text-align: center;
}
hr {
  margin: 16px 16px;
}

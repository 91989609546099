@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css");

:root {
  /* Brand Color */
  --color-poola-blue: #00398f;
  --color-poola-blue-op50: #00398f50;
  --color-poola-orange: #ff7439;
  --color-poola-blue-light: #dee8f9;
  --color-poola-red-light: #fde9e9;
  --color-poola-red: #eb5757;
  --color-poola-orange-light: #fff9f1;

  /* Basic Color */
  --color-black: #000000;
  --color-secondary-black: #444444;
  --color-tertiary-black: #999999;
  --color-icon-gray: #aaaaaa;
  --color-border-gray: #dddddd;
  --color-disabled-gray: #ebebeb;
  --color-bg-gray: #f4f4f4;
  --color-white: #ffffff;
  --color-yellow: #fbea53;
  --color-light-green: #b4e89b;
  --color-deep-red: #cc4545;

  /* System Color */
  --color-negative-red: #eb5757;
}
